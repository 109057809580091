<template>
    <div class="mt-2 medications-main">
        <div class="medications-content">
            <div class="d-flex justify-content-between align-items-end" v-if="clientData && showClientMedications">
                <div class="d-flex">
                    <MemberChangeDropdown :page="page" @action="goToPage" :currentClientId="currentClientId" :currentClientName="clientData.first_name + ' ' + clientData.last_name" @back="back()"/>
                </div>
                <div class="d-flex medication-detail">
                    <span class="me-3">Today's Medication</span>
                    <button>{{ currentFormatedDate }}</button>
                </div>
                <div>
                    <button class="primary text-button rounded-4" @click="showPage('ADD_MEDICATION', null)">
                        <span class="rounded-add-custom material-icons-outlined">add</span>
                        <span class="ms-2">Add a Medication</span>
                    </button>
                </div>
            </div>
            
            <!-- ##### ADD / EDIT / VIEW medication form header ####### -->
            <div v-if="!showClientMedications" class="d-flex justify-content-between align-items-center add-edit-medication-header">
                <div class="d-flex">
                    <MemberChangeDropdown :page="page" @action="goToPage" :currentClientId="currentClientId" :currentClientName="clientData.first_name + ' ' + clientData.last_name" @back="back()" :showMemberListDropdown="false"/>
                </div>
                <div class="medication-detail" :style="[showEditMedication ? {'margin-right': '6rem'} : {'margin-right': '20rem'}]">
                    <span>{{ pageTitle() }}</span>
                </div>
                <div>
                    <!-- <button v-if="showEditMedication" class="primary text-button btn-set-reminder">
                        Set Reminder
                    </button> -->
                </div>
                <!-- <a href="#" @click.prevent="showPage('MEDICATION_LIST', null)">
                    <div class="d-flex align-items-center text-dark">
                        <span class="material-symbols-outlined icon-link-back">
                            chevron_left
                        </span>
                        <span class="link-back">Back</span>
                    </div>
                </a> -->
            </div>

            <template v-if="showClientMedications">
                <div class="medication-card-main" v-if="medicationList && medicationList.length > 0">
                    <MedicationInfoCard v-for="(medication, index) in medicationList" 
                        :key="index" :medicationInfo="medication" 
                        @navigatePage="showPage" 
                        :contactDetails="contactDetails" 
                        @medicationDeleted="medicationDeleted"/>      
                </div>
                <template v-else-if="medicationList && medicationList.length == 0">
                    <div class="w-100 d-flex flex-column justify-content-center align-items-center content-no-medication">
                        <div class="text-no-medication">Medicine list empty, No medications added yet.</div>
                        <img src="@/assets/px/medications-images/Frame.png" width="300" alt="">
                    </div>
                </template>
                <div v-if="loading" style="height: calc(80vh);" class="d-flex justify-content-center align-items-center">
                    <b-spinner variant="primary"></b-spinner>
                </div>
            </template>
            <!-- ##### ADD / EDIT / VIEW medication form ####### -->
            <div class="content-add-edit-medication" v-if="showAddMedication || showEditMedication || showViewMedication">
                <AddEditMedication :medicineInfo="selectedMedicineInfo" @showPage="showPage" :contactDetails="contactDetails"/>
            </div>    
            
        </div>
    </div>
</template>

<script>
    import dayjs from 'dayjs';
    import MemberChangeDropdown from '@/components/general/dropdownButtons/MemberChangeDropdown.vue';
    import MedicationInfoCard from '../../components/client-manager/Medications/MedicationInfoCard.vue';
    import AddEditMedication from '../../components/client-manager/Medications/AddEditMedication.vue';
    import {clients} from '@/util/apiRequests';
    import { getTodaysMedications } from './../../util/helpers/graphql/medications.graphql';
    import { contactDetails } from './../../util/helpers/graphql/common.graphql';

    export default {
        name: 'Medications',
        components: {
            MemberChangeDropdown,
            MedicationInfoCard,
            AddEditMedication
        },
        props: {
            client_id: [String, Number]
        },
        data() {
            return {
                loading: false,
                currentFormatedDate: dayjs().format('MMMM D , YYYY'),
                page: {
                    label: 'Medications',
                    name: 'Medications',
                },
                currentClientId: this.$route.params.client_id,
                showClientMedications: true,
                showAddMedication: false,
                showEditMedication: false,
                showViewMedication: false,
                showMedicationCardOptions: false,
                clientData: null,
                medicationList: null,
                medicationTypesList: null,
                getMedicationDetails: null,
                contactDetails: {
                    contactId: null,
                    familyId: null
                },
                currentPage: null,
                currentDate: dayjs().format('YYYY-MM-DD'),
                medicationId: null,
            }
        },
        methods: {
            async init() {
                this.loading = true;
                this.medicationList = null;
                await this.getClient();
                const { contactId, familyId } = await this.getContactDetails();
                this.contactDetails = {
                    contactId: contactId,
                    familyId: familyId
                }
                this.medicationList = await this.getMedications();
                this.loading = false;
            },
            goToPage(memberChangeResponse) {
                this.$router.push(memberChangeResponse.page);
                this.client_id = memberChangeResponse.page.params.client_id;
                this.init();
            },
            back() {
                if (this.currentPage === "medication_form") {
                    this.showPage('MEDICATION_LIST', null);
                    this.currentPage = null;
                    this.medicationId = null;
                    this.init();
                } else {
                    // this.$router.back();
                    const page = {
                        label: "ClientOverview",
                        name: "ClientOverview", "params": { "record_id": this.client_id }
                    }
                    this.$router.push(page);
                }
            },
            showPage(pageType, medicineInfo) {
                this.selectedMedicineInfo = { pageType, ...medicineInfo};
                switch (pageType) {
                    case 'ADD_MEDICATION':
                        this.showAddMedication = true;
                        this.showEditMedication = false;
                        this.showClientMedications = false;
                        this.showViewMedication = false;
                        this.currentPage = 'medication_form';
                        break;
                    case 'EDIT_MEDICATION':
                        this.showEditMedication = true;
                        this.showClientMedications = false;
                        this.showAddMedication = false;
                        this.showViewMedication = false;
                        this.currentPage = 'medication_form';
                        break;
                    case 'VIEW_MEDICATION':
                        this.showViewMedication = true;
                        this.showEditMedication = false;
                        this.showClientMedications = false;
                        this.showAddMedication = false;
                        this.currentPage = 'medication_form';
                        break;
                    case 'MEDICATION_LIST':
                        this.showClientMedications = true;
                        this.showViewMedication = false;
                        this.showEditMedication = false;
                        this.showAddMedication = false;
                        this.init();
                        break;
                }
            },
            pageTitle() {
                return this.showAddMedication ? 'Add Medication' : 
                this.showEditMedication ? 'Edit Medication' : 
                this.showViewMedication ? this.selectedMedicineInfo?.medicationName : '';
            },
            async getClient() {
                try {
                    const res = await this.$api.get(clients.getClient(this.client_id));
                    this.clientData = res.data?.[0];
                } catch (error) {
                    this.$cl(error);
                }
            },
            async getContactDetails() {
                try {
                    const email = this.clientData.email;
                    this.contactDetails = await contactDetails( email );
                    return {
                        familyId: this.contactDetails.familyId,
                        contactId: this.contactDetails.contactId
                    }
                }
                catch( e ) {
                    this.$toasted.error( e );
                }
            },
            async getMedications() {
                try {
                    const { medicationList } = await getTodaysMedications(this.contactDetails.contactId, this.contactDetails.familyId, this.currentDate);
                    return medicationList;
                }
                catch( e ) {
                    this.$toasted.error( e );
                }
            },
            async deleteMedication() {

            },
            medicationDeleted(medicationId) {
                if( medicationId ) {
                    let i = this.medicationList.findIndex( med => med.medicationId == medicationId );
                    this.medicationList.splice(i, 1);
                }
                // this.init();
            }
        },
        async created() {
            this.init();
        }
    }
</script>

<style scoped>
.medication-loader {
    height: 5rem; 
    width: 5rem; 
    font-weight: bolder;
}
</style>
