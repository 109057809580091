<template>
    <NoButtonModal :open="open" @close="handleClose">
        <div class="modal-header">
            <div class="w-100 d-flex justify-content-between align-items-center">
                <h1 style="font-weight: 700;">Set Medication Reminder</h1>
                <b-tooltip target="close" placement="bottom" title="Close"></b-tooltip>
                <div id="close" class="btn-close-modal" role="button" @click.prevent="$emit('cancel')">
                    <span class="material-icons-outlined">close</span>
                </div>
            </div>
        </div>
        <div class="modal-body">
            <div class="d-flex justify-content-center">
                <h6 style="color: #990202" v-if="!isFormValid">Please fill all fields !</h6>
            </div>
            <form class="medication-reminder" @submit.prevent='setReminderData'>
                <span class="block field-container d-flex align-items-center">
                    <label class="w-40 me-3 text-end">Reminder Time</label>
                    <b-form-timepicker :disabled="pageType === 'VIEW_MEDICATION' ? true : false" class="field-container d-flex align-items-center input" v-model="medicationTimeReminder" :value="medicationTimeReminder" locale="en"></b-form-timepicker>
                </span>
                <Dropdown
                    label="Recurrence"
                    labelClass="w-40 me-3 d-flex justify-content-end"
                    class="field-container d-flex align-items-center"
                    name="recurrence"
                    :options="recurrenceOptions"
                    placeholder="--- Select Recurrence ---"
                    :disableEmpty="true"
                    :disabled="pageType === 'VIEW_MEDICATION' ? true : false"
                    v-model="medicationRecurrence"
                />
                <div class="w-60 d-flex justify-content-end ms-2" v-if="pageType !== 'VIEW_MEDICATION'">
                    <button class="primary btn-enable-reminder">Set Reminder</button>       
                </div>
            </form>
        </div>
    </NoButtonModal>
</template>

<script>
    import moment from "moment";
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    export default {
        name: 'SetReminderModal',
        components: { NoButtonModal },
        props: {
            open: {
                type: Boolean,
                required: true,
            },
            medicationReminderTime: {
                type: String
            },
            recurrence: {
                type: String
            },
            pageType: {
                type: String
            }
        },
        data() {
            return {
                recurrenceOptions: [
                    "Monthly",
                    "Weekly",
                    "Daily"
                ],
                medicationTimeReminder: this.medicationReminderTime,
                medicationRecurrence: this.recurrence,
                isFormValid: true
            }
        },
        methods: {
            handleClose() {
                this.$emit('cancel');
            },
            resetForm() {
                this.medicationTimeReminder = null;
                this.medicationRecurrence = null;
            },
            setReminderData() {
                if( !this.medicationTimeReminder || !this.medicationRecurrence ) {
                    this.isFormValid = false;
                    return;
                }
                const reminderData = {
                    medicationReminderTime: moment( this.medicationTimeReminder, 'HH:mm:ss' ).format('HH:mm'),
                    recurrence: this.medicationRecurrence
                }
                this.medicationTimeReminder = null;
                this.medicationRecurrence = null;
                this.isFormValid = true;
                this.$emit('reminderDetailsUpdated', reminderData);
            }
        }
    };
</script>

<style>

.medication-reminder .b-form-timepicker {
    flex-direction: row-reverse;
}

.medication-reminder .b-form-btn-label-control.form-control > .form-control {
    padding-left: 5px !important;
    padding-top: 12px !important;
    color: #5155C3 !important;
    font-weight: 500;
}

.medication-reminder .b-form-btn-label-control.form-control {
    height: 36px !important;
}

.medication-reminder .bi-clock,.b-icon,.bi {
    color: #5155C3 !important;
}

.medication-reminder .input {
    border-radius: 5px !important;
    height: 33px;
    border: 1px solid #5155C3 !important;
    color: #5155C3 !important;
}

</style>