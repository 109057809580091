<template>
    <div v-bind:class="medicationInfo.createdByUserType == 'client' ? 'tablet-med medication-card' : 'medication-card'" class="" @mouseover="showMedicationCardOptions = true" @mouseleave="showMedicationCardOptions = false">
        <div v-if="deleteInProgress" class="h-100">
            <div class="d-flex justify-content-evenly align-items-center h-100">
                <b-spinner variant="primary"></b-spinner>
                Deletion In Progress
            </div>
        </div>
        <template v-else>
            <div class="d-flex align-items-center">
                <div class="container-img">
                    <img :src="medicationInfo.imageUrl" width="42" alt="">
                </div>
                <div class="d-grid align-items-center ms-3 container-description">
                    <span class="decription-title-1">{{ medicationInfo.medicationName }}</span>
                    <span class="decription-title-2">{{ medicationInfo['nextDosage'].detail }} - {{ medicationInfo['nextDosage'].quantity }}</span>
                    <!-- <div class="d-flex align-items-center decription-title-3">
                        <span class="material-symbols-outlined me-1">schedule</span>{{ medicationInfo['nextDosage'].time }}
                    </div> -->
                    <!-- <span class="decription-title-3">{{ medicationInfo['nextDosage'].time }}</span> -->
                </div>
            </div>
            <div class="card-overlay-options" v-if="showMedicationCardOptions">
                <div class="d-flex justify-content-between h-100 px-4">
                    <div class="d-flex flex-column justify-content-center overlay-med-desc">
                        <span class="decription-title-1">{{ medicationInfo.medicationName }}</span>
                        <span class="description-small mb-2">{{ medicationInfo['nextDosage'].detail }} - {{ medicationInfo['nextDosage'].quantity }}</span>
                        <!-- <div class="d-flex align-items-center decription-title-3">
                            <span class="material-symbols-outlined me-1">schedule</span>{{ medicationInfo['nextDosage'].time }}
                        </div> -->
                        <!-- <span class="description-small">{{ medicationInfo['nextDosage'].time }}</span> -->
                    </div>
                    <div class="d-flex flex-column justify-content-around my-2">
                        <ejs-tooltip
                            class="tooltipcontainer"
                            content="Edit"
                            target="#target"
                            cssClass="customtooltip"
                            position="BottomRight"
                            v-if="medicationInfo.createdByUserType != 'client'"
                        >
                            <span id="target" class="material-symbols-outlined icon icon-edit" @click="showPage('EDIT_MEDICATION', medicationInfo)">edit_square</span>
                        </ejs-tooltip>
                        <ejs-tooltip
                            class="tooltipcontainer"
                            content="View"
                            target="#target"
                            cssClass="customtooltip"
                            position="BottomRight"
                        >
                            <span id="target" class="material-symbols-outlined icon icon-eye" @click="showPage('VIEW_MEDICATION', medicationInfo)">visibility</span>
                        </ejs-tooltip>
                        <ejs-tooltip
                            class="tooltipcontainer"
                            content="Delete"
                            target="#target"
                            cssClass="customtooltip"
                            position="BottomRight"
                            v-if="medicationInfo.createdByUserType != 'client'"
                        >
                            <span id="target" class="material-symbols-outlined icon icon-delete" @click="openDeleteAlert(medicationInfo)">delete</span>
                        </ejs-tooltip>
                    </div>
                </div>
            </div>
        </template>
        
        <!-- ### Alert Warning Popup ##### -->
        <AlertDeleteMedication
            v-if="showDeleteAlert"
            :open="showDeleteAlert" 
            :medication="medicationDetails"
            @cancel="showDeleteAlert = false"
            @handleDelete="handleDelete">
        </AlertDeleteMedication>
    </div>
</template>
<script>
    import AlertDeleteMedication from './DeleteMedicationAlert.vue';
    import { deleteMedicationDetails } from './../../../util/helpers/graphql/medications.graphql'

    export default {
        name: 'MedicationInfoCard',
        components: {
            AlertDeleteMedication
        },
        props: {
            medicationInfo: {
                type: Object,
                required: true
            },
            contactDetails: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                showMedicationCardOptions: false,
                showDeleteAlert: false,
                medicationDetails: null,
                deleteInProgress: false
            }
        },
        methods: {
            showPage(pageType, medicineInfo) {
                this.$emit('navigatePage', pageType, medicineInfo);
            },
            openDeleteAlert( medicationDetails ) {
                this.medicationDetails = medicationDetails;
                this.showDeleteAlert = true;
            },
            async handleDelete() {
                //Logic here
                try {
                    this.showDeleteAlert = false;
                    this.deleteInProgress = true;
                    const { familyId, contactId } = this.contactDetails;
                    const { medicationId, medicationName } = this.medicationDetails;
                    await deleteMedicationDetails( familyId, contactId, medicationId );
                    this.$emit('medicationDeleted', medicationId);
                    this.deleteInProgress = false;
                    this.$toasted.success( `${medicationName} deleted !` );
                }
                catch( e ) {
                    this.deleteInProgress = false;
                    this.$toasted.error( e );
                }
            }
        }
    }
</script>

<style scoped>
    .tablet-med {
        background: #4CBC9A;
    }
</style>