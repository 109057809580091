<template>
    <div>
        <div v-if="!loading">
            <ValidationObserver ref="form">
                <form>
                    <div class="px-4">
                        <div class="flex colx3 wrap">
                            <Input
                                label="Prescriber Name"
                                type="text"
                                class="field-container"
                                placeholder="Enter Prescriber Name"
                                name="prescriberName"
                                labelOptionalText="(Optional)"
                                labelOptionalTextClass="label-optional-text"
                                v-model="medicationInfo.prescriberName"
                                :disabled="medicineInfo.pageType === 'VIEW_MEDICATION'"
                            />
                            <Input
                                label="DEA"
                                type="text"
                                class="field-container"
                                placeholder="DEA"
                                name="DEA"
                                labelOptionalText="(Optional)"
                                labelOptionalTextClass="label-optional-text"
                                v-model="medicationInfo.DEA"
                                :disabled="medicineInfo.pageType === 'VIEW_MEDICATION'"
                            />
                            <Input
                                label="Supervisor"
                                type="text"
                                class="field-container"
                                placeholder="Supervisor"
                                name="supervisor"
                                labelOptionalText="(Optional)"
                                labelOptionalTextClass="label-optional-text"
                                v-model="medicationInfo.supervisor"
                                :disabled="medicineInfo.pageType === 'VIEW_MEDICATION'"
                            />
                        </div>
                        <div class="flex colx3 wrap">
                            <Input
                                label="Prescriber Info Address"
                                type="text"
                                class="field-container"
                                placeholder="Enter Address"
                                name="prescriberInfoAddress"
                                labelOptionalText="(Optional)"
                                labelOptionalTextClass="label-optional-text"
                                v-model="medicationInfo.prescriberInfoAddress"
                                :disabled="medicineInfo.pageType === 'VIEW_MEDICATION'"
                            />
                            <Input
                                label="Prescriber Phone"
                                type="text"
                                class="field-container"
                                placeholder="Enter Prescriber Phone Number"
                                name="prescriberPhone"
                                labelOptionalText="(Optional)"
                                labelOptionalTextClass="label-optional-text"
                                v-model="medicationInfo.prescriberPhone"
                                :disabled="medicineInfo.pageType === 'VIEW_MEDICATION'"
                            />
                            <Input
                                label="ICD-10"
                                type="text"
                                class="field-container"
                                placeholder="Enter ICD"
                                name="ICD10"
                                labelOptionalText="(Optional)"
                                labelOptionalTextClass="label-optional-text"
                                v-model="medicationInfo.ICD10"
                                :disabled="medicineInfo.pageType === 'VIEW_MEDICATION'"
                            />
                        </div>
                        <!-- Start & End Date -->
                        <div class="flex colx2 wrap">
                            <ValidationProvider class="block field-container custom-date-picker" rules="required" v-slot="field" vid="Effective Date" name="Effective Date">
                                <label for="custom-date-input">Effective Date</label>
                                <b-input-group class="mb-3">
                                    <b-form-input
                                        id="custom-date-input"
                                        v-model="medicationInfo.effectiveDate"
                                        type="text"
                                        placeholder="Choose date"
                                        autocomplete="off"
                                        :disabled="medicineInfo.pageType === 'VIEW_MEDICATION'"
                                    ></b-form-input>
                                    <b-input-group-append>
                                        <b-form-datepicker
                                            v-model="medicationInfo.effectiveDate"
                                            button-only
                                            right
                                            locale="en-US"
                                            button-variant="outline-dark"
                                            :today-button="true"
                                            :reset-button="true"
                                            aria-controls="custom-date-input"
                                            :disabled="medicineInfo.pageType === 'VIEW_MEDICATION'"
                                        ></b-form-datepicker>
                                    </b-input-group-append>
                                </b-input-group>
                                <span v-if="field.errors.length" class="errorClass">{{ field.errors[0] }}</span>
                            </ValidationProvider>
                            <ValidationProvider class="block field-container custom-date-picker" rules="required" v-slot="field" vid="End Date" name="End Date">
                                <label for="custom-date-input">End Date</label>
                                <b-input-group class="mb-3">
                                    <b-form-input
                                        id="custom-date-input"
                                        v-model="medicationInfo.endDate"
                                        type="text"
                                        placeholder="Choose date"
                                        autocomplete="off"
                                        :disabled="medicineInfo.pageType === 'VIEW_MEDICATION'"
                                    ></b-form-input>
                                    <b-input-group-append>
                                        <b-form-datepicker
                                            v-model="medicationInfo.endDate"
                                            button-only
                                            right
                                            locale="en-US"
                                            button-variant="outline-dark"
                                            :today-button="true"
                                            :reset-button="true"
                                            aria-controls="custom-date-input"
                                            :disabled="medicineInfo.pageType === 'VIEW_MEDICATION'"
                                        ></b-form-datepicker>
                                    </b-input-group-append>
                                </b-input-group>
                                <span v-if="field.errors.length" class="errorClass">{{ field.errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="flex colx2 wrap">
                            <Input
                                label="Drug (Medication Name)"
                                type="text"
                                class="field-container"
                                placeholder="Enter Medication Name"
                                name="Drug"
                                v-model="medicationInfo.medicationName"
                                :disabled="medicineInfo.pageType === 'VIEW_MEDICATION'"
                                :showAsterick="false"
                                required
                            />
                            <Dropdown
                                label="SIG"
                                class="field-container"
                                name="SIG"
                                :options="SIGOptions"
                                placeholder="Select Quantity & Frequency"
                                :disableEmpty="true"
                                v-model="medicationInfo.SIG"
                                :disabled="medicineInfo.pageType === 'VIEW_MEDICATION'"
                                :showAsterick="false"
                                required
                            />
                        </div>
                        <div class="flex bottom-15">
                            <Input
                                label="Quantity"
                                type="number"
                                class="field-container flex-1 right-15"
                                placeholder="Number of units in bottle"
                                name="quantity"
                                rules="integer"
                                v-model="medicationInfo.quantity"
                                :disabled="medicineInfo.pageType === 'VIEW_MEDICATION'"
                                :showAsterick="false"
                                required
                            />
                            <!-- Pending -->
                            <Input
                                label="Days Supply"
                                type="number"
                                class="field-container flex-1 right-15"
                                placeholder="Select Unit"
                                name="daysSupply"
                                labelOptionalText="(Optional)"
                                labelOptionalTextClass="label-optional-text"
                                rules="integer"
                                v-model="medicationInfo.daysSupply"
                                :disabled="medicineInfo.pageType === 'VIEW_MEDICATION'"
                            />
                            <Input
                                label="Refills"
                                type="number"
                                class="field-container flex-1 right-15"
                                placeholder="Select refill quantity"
                                name="refills"
                                labelOptionalText="(Optional)"
                                labelOptionalTextClass="label-optional-text"
                                rules="integer"
                                v-model="medicationInfo.refills"
                                :disabled="medicineInfo.pageType === 'VIEW_MEDICATION'"
                            />
                            <Checkbox
                                class="block field-container flex center mt-4"
                                name="DAW"
                                id="DAW"
                                label="DAW"
                                v-model="medicationInfo.daw"
                                :disabled="medicineInfo.pageType === 'VIEW_MEDICATION'"
                            />
                        </div>
                        <div class="flex colx4 wrap medication-add">
                            <!-- <span class="block field-container"> -->
                                <ValidationProvider class="block field-container" rules="required" v-slot="field" vid="Units of measure (Medication Type)" name="Units of measure (Medication Type)">
                                    <label>Units of measure (Medication Type)</label>
                                    <multiselect v-model="medicationInfo.medicationType" placeholder="Medication Type" 
                                        label="medicationType" track-by="medicationTypeId"
                                        :options="medicationTypesList" 
                                        :disabled="medicineInfo.pageType === 'VIEW_MEDICATION'">
                                        <template slot="option" slot-scope="props">
                                            <div class="d-flex justify-content-start align-items-center">
                                                <img class="option__image commonClass" :src="props.option.imageUrl" alt="no_img">
                                                <span class="option__title">{{ props.option.medicationType }}</span>
                                            </div>
                                        </template>
                                    </multiselect>
                                    <span v-if="field.errors.length" class="errorClass">{{ field.errors[0] }}</span>
                                </ValidationProvider>
                            <!-- </span> -->

                            <Input
                                label="Weight"
                                type="text"
                                class="field-container position-relative"
                                placeholder="Enter weight"
                                name="weight"
                                labelOptionalText="(Optional)"
                                labelOptionalTextClass="label-optional-text"
                                unitPlaceholderText="lb"
                                rules="OneDecimalPlace"
                                v-model="medicationInfo.weight"
                                :disabled="medicineInfo.pageType === 'VIEW_MEDICATION'"
                            />
                            <Input
                                label="Height"
                                type="text"
                                class="field-container position-relative"
                                placeholder="Enter height"
                                name="height"
                                labelOptionalText="(Optional)"
                                labelOptionalTextClass="label-optional-text"
                                unitPlaceholderText="in"
                                rules="OneDecimalPlace"
                                v-model="medicationInfo.height"
                                :disabled="medicineInfo.pageType === 'VIEW_MEDICATION'"
                            />
                            <!-- <Input
                                label="Medication Time"
                                type="time"
                                class="field-container position-relative"
                                placeholder="Enter Time"
                                name="time"
                                true_type="time"
                                v-model="medicationInfo.time"
                                :disabled="medicineInfo.pageType === 'VIEW_MEDICATION'"
                                :showAsterick="false"
                                required
                            /> -->
                            <div class="block">
                                <label class="dummy-text-class"> Medication Time </label>
                                <b-form-timepicker 
                                    class="field-container d-flex align-items-center" 
                                    v-model="medicationInfo.time" 
                                    :value="medicationInfo.time" 
                                    locale="en" 
                                    style="border-radius: 5px !important; height: 33px; margin: -1px; box-shadow: inset 0 0 0 1px #707070;"
                                    :disabled="medicineInfo.pageType === 'VIEW_MEDICATION'"
                                ></b-form-timepicker>
                            </div>
                        </div>
                        <div class="flex colx2 wrap">
                            <!-- Pending -->
                            <TextArea
                                type="text"
                                id="notesToPharmacist"
                                name="notesToPharmacist"
                                :rows=3
                                :cols=50
                                label="Notes to pharmacist"
                                labelOptionalText="(Optional)"
                                labelOptionalTextClass="label-optional-text"
                                placeholder="Enter notes to pharmacist"
                                v-model="medicationInfo.additionalDetails"
                                :disabled="medicineInfo.pageType === 'VIEW_MEDICATION'"
                            />
                            <TextArea
                                type="text"
                                id="issueTo"
                                name="issueTo"
                                :rows=3
                                :cols=50
                                label="Issue to"
                                placeholder="Enter issued to"
                                v-model="medicationInfo.issueTo"
                                :disabled="medicineInfo.pageType === 'VIEW_MEDICATION'"
                                :showAsterick="false"
                                required
                            />
                        </div>
                        <!-- #### Set Reminder Toggle #### -->
                        <div class="fullwidth pb-4">
                            <span class="fs-5 fw-bolder">Set Reminder</span>
                            <Toggle
                                id="setReminderToggle"
                                class="toggle-switch"
                                :labelClass="'switch'"
                                :spanClass="medicineInfo.pageType === 'VIEW_MEDICATION' ? 'slider slider-disabled': 'slider'"
                                :medicationReminderTime="medicationInfo.medicationReminderTime"
                                :recurrence="medicationInfo.recurrence"
                                @input="toggleSetReminder"
                                :disabled="medicineInfo.pageType === 'VIEW_MEDICATION'"
                                v-model="medicationInfo.isRemind"
                            />
                        </div>
                    </div>
                </form>
                <div class="d-flex justify-content-end" v-if="medicineInfo.pageType === 'ADD_MEDICATION' && medicineInfo.pageType !== 'VIEW_MEDICATION'">
                    <button type="button" class="primary btn-medication-save fw-semibold" @click="addMedication">Save Medication</button> 
                </div>
                <div class="d-flex justify-content-end" v-if="medicineInfo.pageType === 'EDIT_MEDICATION' && medicineInfo.pageType !== 'VIEW_MEDICATION'">
                    <button type="button" class="primary btn-medication-save fw-semibold" @click="updateMedication">Update Medication</button> 
                </div>
                <SetReminderModal 
                    :open="showSetReminderModal" @cancel="closeReminderModal" @reminderDetailsUpdated="updateReminderDetails"
                    :medicationReminderTime="medicationInfo.medicationReminderTime" :recurrence="medicationInfo.recurrence" :pageType="medicineInfo.pageType"/>
            </ValidationObserver>
        </div>
        <div v-if="loading" style="height: calc(80vh);" class="d-flex justify-content-center align-items-center">
            <b-spinner variant="primary" class="medication-loader"></b-spinner>
        </div>
    </div>
    
</template>
<script>
    import moment from 'moment';
    import { ValidationObserver, ValidationProvider } from 'vee-validate';
    import SetReminderModal from '@/components/client-manager/SetReminder/SetReminderModal.vue'
    import dayjs from 'dayjs';
    import { medicationTypes, medicationDetails, addMedicationDetails, updateMedicationDetails } from './../../../util/helpers/graphql/medications.graphql';

    export default {
        name: 'AddEditMedication',
        components: { ValidationObserver, SetReminderModal, ValidationProvider },
        props: {
            medicineInfo: {
                type: Object,
                required: true
            },
            contactDetails: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                SIGOptions: [
                    'Take 1 tablet at bedtime',
                    'Take 1 tablet daily',                                 
                    'Take 1 tablet twice daily',                                  
                    'Take 1 tablet daily in the evening',    
                    'Take 2 tablets once daily',                     
                    'Take 2 tablets at bedtime',                      
                    'Take 2 tablets daily in the evening',      
                    'Take 2 tablets twice daily',                  
                    'Take 1 tablet as needed',                      
                    'Take 2 tablets as needed'
                ],
                medicationTypeOptions: [
                    {imageUrl: require('@/assets/px/medications-images/drops.png'), medicationType: 'Drop', class:"commonClass"},
                    {imageUrl: require('@/assets/px/medications-images/temperature.png'), medicationType: 'Temperature', class:"commonClass"},
                    {imageUrl: require('@/assets/px/medications-images/inhaler.png'), medicationType: 'Inhalation', class:"inhelerTabletClass"},
                    {imageUrl: require('@/assets/px/medications-images/tablet.png'), medicationType: 'Tablet', class:"inhelerTabletClass"},
                    {imageUrl: require('@/assets/px/medications-images/topical_cream.png'), medicationType: 'Cream', class:"commonClass"},
                    {imageUrl: require('@/assets/px/medications-images/injection.png'), medicationType: 'Injection', class:"commonClass"}
                ],
                medicationInfo: {
                    prescriberName: null,
                    DEA: null,
                    supervisor: null,
                    prescriberInfoAddress: null,
                    ICD10: null,
                    effectiveDate: null,
                    endDate: null,
                    prescriberPhone: null,
                    medicationName: null,
                    SIG: null,
                    quantity: null,
                    time: null,
                    daysSupply: null,
                    refills: null,
                    daw: false,
                    medicationType: null,
                    weight: null,
                    height: null,
                    additionalDetails: null,
                    issueTo: null,
                    isRemind: false,
                    medicationReminderTime: null,
                    recurrence: null
                },
                isViewMedication: false,
                showSetReminderModal: false,
                medicationId: null,
                currentDate: dayjs().format('YYYY-MM-DD'),
                medicationTypesList: [],
                loading: false
            }
        },
        methods: {
            async init() {
                this.loading = true;
                this.medicationTypesList = await medicationTypes();
                if (this.medicineInfo.pageType === 'ADD_MEDICATION') {
                    this.loading = false;
                    return;
                }
                else {
                    this.medicationId = this.medicineInfo.medicationId;
                    this.medicationInfo = await this.getMedicationDetails();
                    this.loading = false;
                    
                    if( this.medicationInfo.isRemind && this.medicationInfo.recurrence && this.medicationInfo.medicationReminderTime ) {
                        setTimeout( () => {
                            document.getElementById('setReminderToggle').checked = true;
                        }, 200);
                    }
                }
            },
            async getMedicationDetails() {
                try {
                    const { contactId, familyId } = this.contactDetails;
                    let medicationDetail = await medicationDetails(contactId, familyId, this.currentDate, this.medicationId);
                    for (let key in medicationDetail) {
                        if (key === 'dosageList') {
                            medicationDetail['quantity'] = medicationDetail[key][0].quantity;
                            medicationDetail['time'] = medicationDetail[key][0].time;
                            medicationDetail['dosageId'] = medicationDetail[key][0].id;
                        }

                        if (key === 'daw') {
                            medicationDetail[key] = (medicationDetail[key] === 'true') ? true : false;
                        }

                        if (key === 'medicationTypeId' && medicationDetail[key]) {
                            medicationDetail['medicationType'] = this.medicationTypesList.find(el => el.medicationTypeId === medicationDetail[key]) ?? null;
                        }
                    }
                    return medicationDetail;
                }
                catch( e ) {
                    this.$toasted.error( e );
                }
            },
            toggleSetReminder(value) {
                if( value == false ) {
                    this.medicationInfo.medicationReminderTime = null;
                    this.medicationInfo.recurrence = null;
                    this.showSetReminderModal = false;
                }
                else {
                    this.showSetReminderModal = true;
                }
            },
            async addMedication() {
                try {
                    const form = this.$refs.form;
                    const isFormValid = await form.validate();
                    if (!isFormValid) {
                        this.$toasted.error('Please fill all required fields');
                        return;
                    }

                    if (!this.medicationInfo.medicationType.medicationTypeId) {
                        this.$toasted.error('Please select Units of measure (Medication Type)');
                        return;
                    }

                    if( this.medicationInfo.effectiveDate > this.medicationInfo.endDate ) {
                        this.$toasted.error("Effective date can't be greater than End date !");
                        return;
                    }

                    const { contactId, familyId } = this.contactDetails;
                    let addMedicationPayload = {
                        contactId: contactId,
                        DEA: this.medicationInfo.DEA ? this.medicationInfo.DEA : null, 
                        SIG: this.medicationInfo.SIG ? this.medicationInfo.SIG : null,
                        daw: this.medicationInfo.daw ? this.medicationInfo.daw : null, 
                        daysSupply: this.medicationInfo.daysSupply ? this.medicationInfo.daysSupply : null,
                        effectiveDate: this.medicationInfo.effectiveDate,
                        endDate: this.medicationInfo.endDate,
                        prescriberPhone: this.medicationInfo.prescriberPhone ? this.medicationInfo.prescriberPhone : null,
                        isRemind: this.medicationInfo.isRemind,
                        issueTo: this.medicationInfo.issueTo,
                        medicationName: this.medicationInfo.medicationName,
                        medicationTypeId: this.medicationInfo.medicationType.medicationTypeId,
                        prescriberName: this.medicationInfo.prescriberName ? this.medicationInfo.prescriberName : null,
                        refills: this.medicationInfo.refills ? this.medicationInfo.refills : null,
                        dosageList: { quantity: this.medicationInfo.quantity, time: moment( this.medicationInfo.time, 'HH:mm:ss' ).format('HH:mm'), detail: this.medicationInfo.SIG },
                        additionalDetails: this.medicationInfo.additionalDetails ? this.medicationInfo.additionalDetails : null,
                        ICD10: this.medicationInfo.ICD10 ? this.medicationInfo.ICD10 : null,
                        height: this.medicationInfo.height ? this.medicationInfo.height : null,
                        weight: this.medicationInfo.weight ? this.medicationInfo.weight : null,
                        supervisor: this.medicationInfo.supervisor ? this.medicationInfo.supervisor : null,
                        prescriberInfoAddress: this.medicationInfo.prescriberInfoAddress ? this.medicationInfo.prescriberInfoAddress : null
                    }

                    if( this.medicationInfo.medicationReminderTime ) Object.assign( addMedicationPayload, { medicationReminderTime: this.medicationInfo.medicationReminderTime } );
                    if( this.medicationInfo.recurrence ) Object.assign( addMedicationPayload, { recurrence: this.medicationInfo.recurrence } );

                    this.loading = true;
                    await addMedicationDetails(familyId, addMedicationPayload);
                    this.loading = false;
                    this.$toasted.success('Medication Saved Successfully');
                    this.$emit('showPage', 'MEDICATION_LIST', null);
                } catch (e) {
                    this.$toasted.error("Failed to save medication !");
                }
            },
            async updateMedication() {
                try {
                    const form = this.$refs.form;
                    const isFormValid = await form.validate();
                    if (!isFormValid) {
                        this.$toasted.error('Please fill all required fields');
                        return;
                    }

                    if (!this.medicationInfo.medicationTypeId) {
                        this.$toasted.error('Please select Units of measure (Medication Type)');
                        return;
                    }

                    if( this.medicationInfo.effectiveDate > this.medicationInfo.endDate ) {
                        this.$toasted.error("Effective date can't be greater than End date !");
                        return;
                    }

                    const { contactId, familyId } = this.contactDetails;
                    let editMedicationPayload = {
                        contactId: contactId,
                        DEA: this.medicationInfo.DEA ? this.medicationInfo.DEA : null, 
                        SIG: this.medicationInfo.SIG ? this.medicationInfo.SIG : null,
                        daw: this.medicationInfo.daw ? this.medicationInfo.daw : null, 
                        daysSupply: this.medicationInfo.daysSupply ? this.medicationInfo.daysSupply : null,
                        effectiveDate: this.medicationInfo.effectiveDate,
                        endDate: this.medicationInfo.endDate,
                        prescriberPhone: this.medicationInfo.prescriberPhone ? this.medicationInfo.prescriberPhone : null,
                        isRemind: this.medicationInfo.isRemind,
                        issueTo: this.medicationInfo.issueTo,
                        medicationName: this.medicationInfo.medicationName,
                        medicationTypeId: this.medicationInfo.medicationType.medicationTypeId,
                        prescriberName: this.medicationInfo.prescriberName ? this.medicationInfo.prescriberName : null,
                        refills: this.medicationInfo.refills ? this.medicationInfo.refills : null,
                        dosageList: { quantity: this.medicationInfo.quantity, time: moment( this.medicationInfo.time, 'HH:mm:ss' ).format('HH:mm'), detail: this.medicationInfo.SIG, id: this.medicationInfo.dosageId },
                        additionalDetails: this.medicationInfo.additionalDetails ? this.medicationInfo.additionalDetails : null,
                        ICD10: this.medicationInfo.ICD10 ? this.medicationInfo.ICD10 : null,
                        height: this.medicationInfo.height ? this.medicationInfo.height : null,
                        weight: this.medicationInfo.weight ? this.medicationInfo.weight : null,
                        supervisor: this.medicationInfo.supervisor ? this.medicationInfo.supervisor : null,
                        prescriberInfoAddress: this.medicationInfo.prescriberInfoAddress ? this.medicationInfo.prescriberInfoAddress : null
                    };

                    if( this.medicationInfo.medicationReminderTime ) Object.assign( editMedicationPayload, { medicationReminderTime: this.medicationInfo.medicationReminderTime } );
                    if( this.medicationInfo.recurrence ) Object.assign( editMedicationPayload, { recurrence: this.medicationInfo.recurrence } );


                    this.loading = true;
                    await updateMedicationDetails(familyId, editMedicationPayload, this.medicationId);
                    this.loading = false;
                    this.$toasted.success('Medication Updated Successfully');
                    this.$emit('showPage', 'MEDICATION_LIST', null);
                } catch (e) {
                    this.$toasted.error("Failed to update medication !");
                }
            },
            resetForm() {
                this.medicationInfo = {
                    prescriberName: '',
                    DEA: '',
                    supervisor: '',
                    prescriberInfoAddress: '',
                    ICD10: '',
                    effectiveDate: dayjs().format('YYYY-MM-DD'),
                    endDate: dayjs().format('YYYY-MM-DD'),
                    prescriberPhone: dayjs().format('YYYY-MM-DD'),
                    medicationName: '',
                    SIG: '',
                    quantity: '',
                    time: '',
                    daysSupply: '',
                    refills: '',
                    daw: false,
                    medicationType: null,
                    weight: '',
                    height: '',
                    notesToPharmacist: '',
                    issueTo: '',
                    // medicationReminderTime: '',
                    // recurrence: ''
                };
                requestAnimationFrame(() => {
                    this.$refs.form.reset();
                });
            },
            closeReminderModal() {
                this.showSetReminderModal = false
                document.getElementById('setReminderToggle').checked = false;
            },
            updateReminderDetails(arg1) {
                const { medicationReminderTime, recurrence } = arg1;
                this.medicationInfo.medicationReminderTime = medicationReminderTime;
                this.medicationInfo.recurrence = recurrence;
                this.showSetReminderModal = false;
            }
        },
        created() {
            this.init();
        }
    }
</script>
<style>
.medication-add .b-form-timepicker {
    flex-direction: row-reverse;
}

.medication-add .b-form-btn-label-control.form-control > .form-control {
    padding-left: 5px !important;
    padding-top: 12px !important;
    font-weight: 500;
}

.medication-add .b-form-btn-label-control.form-control {
    height: 36px !important;
}

.medication-add .input {
    border-radius: 5px !important;
    height: 33px;
    box-shadow: inset 0 0 0 1px #707070;
}
</style>