import { API } from 'aws-amplify';
import { getMedicationDetails, getMedicationList, getMedicationTypes } from '../../graphql/queries';
import { deleteMedication, createMedication, updateMedication } from '../../graphql/mutations';

export const getTodaysMedications = async( contactId, familyId, date ) => {
    const { data: { getMedicationList: medicationList }, errors } = await API.graphql({ query: getMedicationList, variables: { familyId, contactId, date } });
    if( errors ) {
        return new Error( errors[0].message );
    }
    return medicationList;
}

export const medicationTypes = async() => {
    const { data: { getMedicationTypes: medicationTypes }, errors } = await API.graphql({ query: getMedicationTypes });
    if( errors ) {
        return new Error( errors[0].message );
    }
    return medicationTypes;
}

export const medicationDetails = async(contactId, familyId, date, medicationId) => {
    const { data: { getMedicationDetails: details }, errors } = await API.graphql({ query: getMedicationDetails, variables: { familyId, contactId, date, medicationId } });
    if( errors ) {
        return new Error( errors[0].message );
    }
    return details;
}

export const addMedicationDetails = async(familyId, medication) => {
    const { data: { createMedication: details }, errors } = await API.graphql({ query: createMedication, variables: { familyId, medication }});
    if( errors ) {
        return new Error( errors[0].message );
    }
    return details;
}

export const updateMedicationDetails = async(familyId, medication, medicationId) => {
    const { data: { updateMedication: details }, errors } = await API.graphql({ query: updateMedication, variables: { familyId, medication, medicationId } });
    if( errors ) {
        return new Error( errors[0].message );
    }
    return details;
}

export const deleteMedicationDetails = async(familyId, contactId, medicationId) => {
    const { data: { deleteMedication: details }, errors } = await API.graphql({ query: deleteMedication, variables: { familyId, contactId, medicationId } });
    if( errors ) {
        return new Error( errors[0].message );
    }
    return details;
}